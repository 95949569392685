import { render, staticRenderFns } from "./PeriodSelectorUpdatedComponent.vue?vue&type=template&id=a8fcaed2&scoped=true&"
import script from "./PeriodSelectorUpdatedComponent.vue?vue&type=script&lang=ts&"
export * from "./PeriodSelectorUpdatedComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8fcaed2",
  null
  
)

export default component.exports