import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import { startOfWeek, endOfMonth } from 'date-fns';
let PeriodSelectorComponentUpdated = class PeriodSelectorComponentUpdated extends Vue {
    constructor() {
        super(...arguments);
        this.menu = false;
        this.menuFinal = false;
        this.loading = false;
        this.dataInicial = this.propPermiteNull ? null : new Date();
        this.dataFinal = this.propPermiteNull ? null : new Date();
        this.items = [
            { id: 0, name: 'Hoje' },
            { id: 1, name: 'Essa semana' },
            { id: 2, name: 'Esse mês' },
            { id: 3, name: 'Esse ano' },
            { id: 4, name: 'Últimos 7 dias' },
            { id: 5, name: 'Últimos 30 dias' },
            { id: 6, name: 'Mês anterior' },
            { id: 7, name: 'Próximo mês' }
        ];
        this.selectedItem = this.propTypeDefault;
    }
    onChangeDefault(val) {
        this.setDefault();
    }
    onChangeValue(val) {
        this.dataInicial = val[0];
        this.dataFinal = val[1];
    }
    onChangeHandlerInicial(val) {
        this.$emit('input', [
            this.formatDateISO(val),
            this.formatDateISO(this.dataFinal)
        ]);
    }
    onChangeHandlerFinal(val) {
        this.$emit('input', [
            this.formatDateISO(this.dataInicial),
            this.formatDateISO(val)
        ]);
    }
    setDefault() {
        const date = new Date();
        switch (this.selectedItem) {
            case 0:
                this.dataInicial = date;
                this.dataFinal = date;
                break;
            case 1:
                this.dataInicial = startOfWeek(date, { weekStartsOn: 1 });
                this.dataFinal = new Date(this.dataInicial.getTime() + 6 * 24 * 60 * 60 * 1000);
                break;
            case 2:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case 3:
                this.dataInicial = new Date(date.getFullYear(), 0, 1);
                this.dataFinal = new Date(date.getFullYear(), 11, 31);
                break;
            case 4:
                this.dataInicial = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
                this.dataFinal = date;
                break;
            case 5:
                this.dataInicial = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
                this.dataFinal = date;
                break;
            case 6:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                this.dataFinal = endOfMonth(this.dataInicial);
                break;
            case 7:
                const proximoMes = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                this.dataInicial = proximoMes;
                this.dataFinal = endOfMonth(proximoMes);
                break;
            default:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
        }
    }
    formatDateISO(date) {
        if (!date)
            return null;
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    }
    mounted() {
        if (this.propPermiteNull) {
            return;
        }
        this.loading = true;
        this.setDefault();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: String, default: 'Data' })
], PeriodSelectorComponentUpdated.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: Number, default: 2 })
], PeriodSelectorComponentUpdated.prototype, "propTypeDefault", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], PeriodSelectorComponentUpdated.prototype, "propPermiteNull", void 0);
__decorate([
    Prop()
], PeriodSelectorComponentUpdated.prototype, "value", void 0);
__decorate([
    Watch('selectedItem')
], PeriodSelectorComponentUpdated.prototype, "onChangeDefault", null);
__decorate([
    Watch('value')
], PeriodSelectorComponentUpdated.prototype, "onChangeValue", null);
__decorate([
    Watch('dataInicial')
], PeriodSelectorComponentUpdated.prototype, "onChangeHandlerInicial", null);
__decorate([
    Watch('dataFinal')
], PeriodSelectorComponentUpdated.prototype, "onChangeHandlerFinal", null);
PeriodSelectorComponentUpdated = __decorate([
    Component({
        components: {
            DateFieldComponent
        }
    })
], PeriodSelectorComponentUpdated);
export default PeriodSelectorComponentUpdated;
