import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { cdaStore } from '@/utils/store-accessor';
import PeriodSelectorUpdatedComponent from '@/components/PeriodSelectorUpdatedComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
let Cupom = class Cupom extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome' },
            { text: 'Entrada', value: 'total_entrada' },
            { text: 'Saída', value: 'total_saida' },
            { text: 'Saldo', value: 'total_saldo' }
        ];
        this.selectPeriodDate = [];
        this.search = '';
        this.loading = false;
        this.items = [];
        this.selectedTab = 6;
        this.menu = false;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    async buscar() {
        this.loading = true;
        this.items = await cdaStore.getCadernoCda({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1]
        });
        this.loading = false;
    }
    mounted() {
        const today = new Date();
        const last30Days = new Date();
        last30Days.setDate(today.getDate() - 30);
        this.selectPeriodDate = [
            last30Days.toISOString().substr(0, 10),
            today.toISOString().substr(0, 10)
        ];
        this.buscar();
    }
};
Cupom = __decorate([
    Component({
        components: {
            PeriodSelectorUpdatedComponent,
            CustomTabsComercialComponent
        }
    })
], Cupom);
export default Cupom;
